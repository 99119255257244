<template>
  <div class="app-container">
    <section>
      <h2>基本信息</h2>
      <div class="info">
        <div class="row flex">
          <p>
            <label>MacID：{{ form.mac }}</label>
          </p>
          <p>
            <label>CameraID：{{ form.cameraId }}</label>
          </p>
          <p>
            <label>摄像头名称：{{ form.cameraName }}</label>
          </p>
        </div>
        <div class="row flex">
          <p>
            <label>盒子MacID：{{ form.boxMac }}</label>
          </p>
          <p>
            <label>盒子端口号：{{ form.boxPort }}</label>
          </p>
          <p>
            <label>客户名称：{{ form.customerName }}</label>
          </p>
        </div>
        <div class="row flex">
          <p>
            <label>CID：{{ form.customerId }}</label>
          </p>
          <p>
            <label>门店名称：{{ form.shopName }}</label>
          </p>
          <p>
            <label>ShopID：{{ form.shopId }}</label>
          </p>
        </div>
      </div>
    </section>
    <section>
      <h2>安装信息</h2>
      <div class="info">
        <div class="row flex">
          <p>
            <label>安装位置：{{ form.installLocation }}</label>
          </p>
          <p>
            <label
              >安装高度：<span v-show="form.installHeight">{{ form.installHeight }}m</span></label
            >
          </p>
          <p>
            <label
              >安装角度：<span v-show="form.installAngle">{{ form.installAngle }}°</span></label
            >
          </p>
        </div>
      </div>
      <h2 class="title">硬件信息</h2>
      <div class="info">
        <div class="row flex">
          <p>
            <label>品牌：{{ form.brand }}</label>
          </p>
          <p>
            <label>型号：{{ form.model }}</label>
          </p>
          <p>
            <label
              >摄像头焦距：<span v-show="form.focalLength">{{ form.focalLength }}mm</span></label
            >
          </p>
        </div>
      </div>
    </section>
    <section>
      <h2>运行信息</h2>
      <div class="info">
        <div class="row flex">
          <p>
            <label>计算码流：{{ form.dataRate }}</label>
          </p>
          <p>
            <label>入库时间：{{ form.wareHousingTime | formatTime }}</label>
          </p>
          <p>
            <label>绑定时间：{{ form.bindTime | formatTime }}</label>
          </p>
        </div>
        <div class="row flex">
          <p>
            <label>在线状态：{{ form.onlineStatus }}</label>
          </p>
          <p>
            <label>最后心跳时间：{{ form.lastHeartBeatTime | formatTime }}</label>
          </p>
          <p>
            <label>业务程序：{{ form.runningProcess ? form.runningProcess.join() : '' }}</label>
          </p>
        </div>
        <div class="row flex">
          <p>
            <label>区域配置：{{ form.areaConfig ? form.areaConfig.join() : '' }}</label>
          </p>
          <p>
            <label>上传视频：{{ form.uploadVideoStatus }}</label>
          </p>
          <p>
            <label>回放方式：{{ form.boxReplay ? '盒子端播放' : '服务器播放' }}</label>
          </p>
        </div>
      </div>
      <h2 class="title">摄像头参数</h2>
      <div class="info">
        <div class="row flex">
          <p>
            <label
              >快门时间：<span v-show="form.shutterTime">{{ form.shutterTime }}s</span></label
            >
          </p>
          <p>
            <label>宽动态：{{ form.wdrStatus }}</label>
          </p>
          <p>
            <label>宽动态等级:{{ form.wdrLevel }}</label>
          </p>
        </div>
        <div class="row flex">
          <p>
            <label>网络配置：{{ form.netConfig }}</label>
          </p>
          <p>
            <label>时间配置：{{ form.timeConfig }}</label>
          </p>
        </div>
      </div>
      <h2 class="title">主码流配置</h2>
      <div class="info">
        <div class="row flex">
          <p>
            <label>分辨率(主)：{{ form.majorCamVideoConfig.resolution }}</label>
          </p>
          <p>
            <label>图像质量(主):{{ form.majorCamVideoConfig.videoQuality }}</label>
          </p>
          <p>
            <label>视频编码(主):{{ form.majorCamVideoConfig.encode }}</label>
          </p>
        </div>
        <div class="row flex">
          <p>
            <label>关键帧间隔(主):{{ form.majorCamVideoConfig.keyFrameInterval }}</label>
          </p>
          <p>
            <label
              >帧率(主)：<span v-show="form.majorCamVideoConfig.frameRate"
                >{{ form.majorCamVideoConfig.frameRate }}fps</span
              ></label
            >
          </p>
          <p>
            <label
              >码流上限(主)：<span v-show="form.majorCamVideoConfig.dataRateUpperLimit"
                >{{ form.majorCamVideoConfig.dataRateUpperLimit }}Kbps</span
              ></label
            >
          </p>
        </div>
        <div class="row flex">
          <p>
            <label>码率类型(主):{{ form.majorCamVideoConfig.fpsType }}</label>
          </p>
        </div>
      </div>
      <h2 class="title">次码流配置</h2>
      <div class="info">
        <div class="row flex">
          <p>
            <label>分辨率(次)：{{ form.minorCamVideoConfig.resolution }}</label>
          </p>
          <p>
            <label>图像质量(次):{{ form.minorCamVideoConfig.videoQuality }}</label>
          </p>
          <p>
            <label>视频编码(次):{{ form.minorCamVideoConfig.encode }}</label>
          </p>
        </div>
        <div class="row flex">
          <p>
            <label>关键帧间隔(次):{{ form.minorCamVideoConfig.keyFrameInterval }}</label>
          </p>
          <p>
            <label
              >帧率(次)：<span v-show="form.minorCamVideoConfig.frameRate"
                >{{ form.minorCamVideoConfig.frameRate }}fps</span
              ></label
            >
          </p>
          <p>
            <label
              >码流上限(次)：<span v-show="form.minorCamVideoConfig.dataRateUpperLimit"
                >{{ form.minorCamVideoConfig.dataRateUpperLimit }}Kbps</span
              ></label
            >
          </p>
        </div>
        <div class="row flex">
          <p>
            <label>码率类型(次):{{ form.minorCamVideoConfig.fpsType }}</label>
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { getMngInfo } from 'src/api/legacy'

export default {
  data() {
    return {
      cameraid: '',
      camerainfo: {},
      cameraConfig: {},
      form: {},
    }
  },
  methods: {
    getMngInfo() {
      getMngInfo({
        cameraid: this.cameraid,
      }).then((res) => {
        if (res && res.status === 0) {
          this.camerainfo = res.data
          this.cameraConfig = res.data.config
        }
      })
    },
  },
  filters: {
    // 格式化时间
    formatTime(time) {
      if (!time) return
      const val = new Date(time)
      return (
        val.getFullYear() +
        '-' +
        (val.getMonth() + 1 > 9 ? val.getMonth() + 1 : '0' + (val.getMonth() + 1)) +
        '-' +
        (val.getDate() > 9 ? val.getDate() : '0' + val.getDate()) +
        ' ' +
        (val.getHours() > 9 ? val.getHours() : '0' + val.getHours()) +
        ':' +
        (val.getMinutes() > 9 ? val.getMinutes() : '0' + val.getMinutes()) +
        ':' +
        (val.getSeconds() > 9 ? val.getSeconds() : '0' + val.getSeconds())
      )
    },
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {
    this.cameraid = this.$route.query.cameraid
    // this.getMngInfo();
    this.form = JSON.parse(localStorage.getItem('cameraInfo'))
  },
}
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.containerX .contentX {
  padding-left: 0;
  padding-right: 0;
  background: #eee;
}
.titletop {
  width: 100%;
  height: 80px;
  line-height: 80px;
  border-bottom: 1px solid #d1d1d1;
  padding: 0px 20px;
  background: #fff;
}

.titletop span {
  font-size: 14px;
  color: #5c76cc;
}

.titletop span i {
  color: #333;
}

.content {
  padding: 40px;
  font-size: 12px;
}
.content ul {
  flex: 1;
  display: inline-block;
}
.content ul li {
  margin-bottom: 20px;
}
.content ul li .left + div {
  flex: 1;
}
.content ul li .left {
  width: 62px;
}

section {
  padding: 10px 30px;
  background: #fff;
  margin: 10px;
}
section h2 {
  font-weight: bold;
  margin-bottom: 20px;
}
section .row:not(:last-child) {
  margin: 20px 0;
}
.row p {
  width: 25%;
}
.title {
  margin-top: 20px;
}
</style>